.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #1a1a1d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h6 {
  color: #5e5e60;
  font-family: "Roboto", sans-serif;
}

h2 {
  font-family: "Quicksand", sans-serif;
  color: white;
}
